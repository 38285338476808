import React, { useEffect, useRef } from "react";

import { Controller, useFormContext } from "react-hook-form";
import Select, {
	components,
	ActionMeta,
	Props as SelectProps,
} from "react-select";

import iconCaretDown from "assets/images/icons/arrow_down.svg";
import { ConnectForm, UseFormProps } from "helpers/form";

export type SelectProp = SelectProps;

export interface Props extends SelectProps {
	name?: string;
	errorMessage?: string;
	controlHeight?: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CaretDownNormalIcon = ({ menuIsOpen }: any) => (
	<img
		src={iconCaretDown}
		alt="icon-arrow"
		style={{
			transform: menuIsOpen && "rotate(180deg)",
			transition: "all 0.3s ease",
		}}
	/>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DropdownIndicator = (props: any) => (
	<components.DropdownIndicator {...props}>
		<CaretDownNormalIcon menuIsOpen={props.selectProps.menuIsOpen} />
	</components.DropdownIndicator>
);

export const Pulldown: React.FC<Props> = ({
	name,
	isDisabled,
	errorMessage,
	...innerProps
}) => {
	return (
		<div className="a-pulldown">
			<Select
				{...innerProps}
				isSearchable={false}
				noOptionsMessage={() => "Không tìm thấy"}
				styles={{
					singleValue: (base) => ({
						...base,
						color: "#6d6e70",
						fontWeight: "bold",
					}),
					control: (base) => ({
						...base,
						height: "43px",
						minHeight: "43px",
						paddingLeft: "8px",
						paddingRight: "6px",
						color: "#000",
						fontSize: "14px",
						fontWeight: "bold",
						borderRadius: "8px",
						borderWidth: "2px",
						borderColor: "rgb(18, 124, 47)",
						background: `linear-gradient(
							90deg,
							rgb(255, 255, 255) 0%,
							rgb(245, 245, 245) 100%
						)`,
						"&:hover,&:focus,&:focus-within": {
							borderColor: "#9ddcae",
						},
						cursor: "pointer",
						boxShadow: "unset",
						transition: " 0.3s ease-in-out",
					}),
					placeholder: (base) => ({
						...base,
						color: "#AEAEAE",
						marginLeft: "10px",
					}),
					valueContainer: (base) => ({
						...base,
						paddingLeft: 0,
						paddingRight: 0,
					}),
					option: (base, state) => ({
						...base,
						display: "flex",
						alignItems: "center",
						height: "36px",
						cursor: "pointer",
						fontWeight: state.isSelected ? "500" : "400",
						color: state.isSelected ? "#3BA558" : "#6d6e70",
						backgroundColor:
							state.isSelected || state.isFocused ? "#F7F9FA" : "transparent",
						"&:hover": {
							backgroundColor: "#F7F9FA",
						},
					}),
					menu: (base) => ({ ...base, zIndex: 4 }),
					menuList: (base) => ({
						...base,
						paddingTop: "12px",
						paddingBottom: "12px",
						fontSize: "14px",
						maxHeight: 200,
						backgroundColor: "#fff",
						borderRadius: 4,
						"::-webkit-scrollbar": {
							width: 8,
						},
						"::-webkit-scrollbar-track": {
							background: "#F2F8FB",
						},
						"::-webkit-scrollbar-thumb": {
							background: "#3BA558",
							borderRadius: 20,
						},
					}),
					dropdownIndicator: (base) => ({
						...base,
						padding: "0",
						color: "#fff",
					}),
				}}
				components={{
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					DropdownIndicator: (props: any) => DropdownIndicator(props),
					IndicatorSeparator: () => null,
				}}
			/>
			{errorMessage && (
				<div className="a-pulldown_errormessage">{errorMessage}</div>
			)}
		</div>
	);
};

interface PulldownHookFormProps extends Props {
	name: string;
}

export const PulldownHookForm: React.FC<PulldownHookFormProps> = (props) => {
	const { name, onChange, value } = props;
	const { watch, setValue } = useFormContext();
	const firstRenderRef = useRef<boolean>(true);
	const formValue = watch(name);
	useEffect(() => {
		if (onChange && !firstRenderRef.current) {
			onChange(
				formValue,
				(null as unknown) as ActionMeta<{ label: string; value: string }>
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [name, formValue]);

	useEffect(() => {
		if (!firstRenderRef.current) {
			setValue(name, value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [name, JSON.stringify(value)]);

	useEffect(() => {
		firstRenderRef.current = false;
	}, []);

	return (
		<ConnectForm>
			{({ control, errors }: UseFormProps) => (
				<Controller
					as={
						<Pulldown
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...props}
							errorMessage={
								errors[props.name] ? errors[props.name].message : undefined
							}
						/>
					}
					defaultValue={props.defaultValue}
					options={props.options}
					control={control}
					name={props.name}
				/>
			)}
		</ConnectForm>
	);
};
