/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
import React, { useMemo, useState } from "react";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { Button } from "components/atoms/Button";
import { Icon } from "components/atoms/Icon";
import { Image } from "components/atoms/Image";
import { TextField } from "components/atoms/TextField";
import { toastSingleMode } from "components/atoms/Toastify";
import BillSelect from "components/molecules/BillSelect";
import { formatTime, resizeImage } from "helpers/utils";
import useDidMount from "hooks/useDidMount";
import useWindowDimensions from "hooks/useWindowDemensions";
import { getAllBillService, submitSelectBillService } from "services/PG";
import { BillForSelect, ProductOfBillSelect } from "services/PG/types";

type Props = {
	handleContinue: (data: ProductOfBillSelect) => void;
};

const SelectBill: React.FC<Props> = ({ handleContinue }) => {
	const [loading, setLoading] = useState(false);
	// {
	// 	id: 1,
	// 	image:
	// 		"http://ensure-doiqua-2023-cms.3forcom.net/storage/receipt/2/uvA9kmBYfbpjhzHfrC9a8O20NuLXQnfFUuTtoNnT.jpg",
	// 	createdAt: new Date().toISOString(),
	// 	isUsed: false,
	// },
	// {
	// 	id: 2,
	// 	image:
	// 		"https://ensure-doiqua-2023.3forcom.net/storage/receipt/2/0S7aZKJPr5Frtq4oZxhtWNUtMpBp5dyEanS4qceu.jpg",
	// 	createdAt: new Date().toISOString(),
	// 	isUsed: false,
	// },
	const [bills, setBills] = useState<BillForSelect[]>([]);
	const [selected, setSelected] = useState<number[]>([]);
	const [listCode, setListCode] = useState<{ id: number; text: string }[]>([]);
	const [imageViewer, setImageViewer] = useState("");
	const [showCapture, setShowcapture] = useState(false);
	const [showInputCode, setShowInputCode] = useState(-1);
	const [inputCode, setInputCode] = useState("");

	const [images, setImages] = useState<{ id: number; file: File }[]>([]);

	const { height } = useWindowDimensions();

	const errorFunc = (message: string) => {
		toastSingleMode({
			type: "error",
			description: message,
		});
	};

	const getAllBillAction = async () => {
		try {
			setLoading(true);
			const data = await getAllBillService();
			setBills(data);
			setLoading(false);
		} catch (error: any) {
			if (error?.response?.data?.errors?.[0]?.code === "4225") {
				errorFunc(
					`Bill thứ ${
						error?.response?.data?.errors?.[0].field.split(".")[1]
					} đã được sử dụng`
				);
			} else {
				errorFunc(`Đã xãy ra lỗi xảy ra!`);
			}
			setLoading(false);
		}
	};

	const submitSelectBill = async () => {
		try {
			setLoading(true);
			const data = await submitSelectBillService(
				selected,
				undefined,
				selected.reduce((prev: string[], curr) => {
					const finder = listCode.find((item) => item.id === curr);
					if (finder) {
						return [...prev, finder.text];
					}
					return prev;
				}, [])
			);
			handleContinue(data);
			setLoading(false);
		} catch (error: any) {
			if (error?.response?.data?.errors?.[0]?.code === "4225") {
				errorFunc(
					`Bill thứ ${
						error?.response?.data?.errors?.[0].field.split(".")[1]
					} đã được sử dụng`
				);
			} else {
				errorFunc(`Đã xãy ra lỗi xảy ra!`);
			}
			setLoading(false);
		}
	};

	const checkExistBill = async () => {
		try {
			setLoading(true);
			await submitSelectBillService([bills[showInputCode].id], undefined, [
				inputCode,
			]);
			setListCode([
				...listCode,
				{ id: bills[showInputCode].id, text: inputCode },
			]);
			setLoading(false);
		} catch (error: any) {
			if (error?.response?.data?.errors?.[0]?.code === "4225") {
				errorFunc(`Bill đã được sử dụng`);
				const clone = bills.slice();
				clone[showInputCode] = { ...clone[showInputCode], isUsed: true };
				setBills(clone);
				setSelected(
					selected.filter((item) => item !== bills[showInputCode].id)
				);
			} else {
				errorFunc(`Đã xãy ra lỗi xảy ra!`);
			}
			setLoading(false);
		} finally {
			setShowInputCode(-1);
			setInputCode("");
		}
	};

	const checkExistBillCapture = async () => {
		setListCode([...listCode, { id: showInputCode, text: inputCode }]);
		setShowInputCode(-1);
		setInputCode("");
	};

	const submitBillCapture = async () => {
		try {
			setLoading(true);
			const data = await submitSelectBillService(
				undefined,
				selected.reduce((prev: File[], curr) => {
					const finder = images.find((item) => item.id === curr);
					if (finder) {
						return [...prev, finder.file];
					}
					return prev;
				}, []),
				selected.reduce((prev: string[], curr) => {
					const finder = listCode.find((item) => item.id === curr);
					if (finder) {
						return [...prev, finder.text];
					}
					return prev;
				}, [])
			);
			handleContinue(data);
			setLoading(false);
		} catch (error: any) {
			if (error?.response?.data?.errors?.[0]?.code === "4225") {
				errorFunc(
					`Bill thứ ${
						Number(error?.response?.data?.errors?.[0].field.split(".")[1]) + 1
					} đã được sử dụng`
				);
			} else {
				errorFunc(`Đã xãy ra lỗi xảy ra!`);
			}
			setLoading(false);
		}
	};

	const handleCapture = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e?.target?.files?.[0];
		if (file) {
			await resizeImage(file, () => {
				setImages([
					...images,
					{ id: !images.length ? 0 : images[images.length - 1].id + 1, file },
				]);
			});
		}
	};

	const billNull = useMemo(
		() => bills.length === 0 || bills.every((item) => item.isUsed === true),
		[bills]
	);

	useDidMount(() => {
		getAllBillAction();
	});

	return (
		<>
			<div className="t-selectBill" style={{ height: `${height - 64}px` }}>
				<div className="t-selectBill_list">
					{bills.map((item, idx) => (
						<div className="t-selectBill_item" key={item.id}>
							<BillSelect
								imgSrc={item.image}
								isUsed={item.isUsed}
								time={formatTime(new Date(item.createdAt))}
								checked={selected.includes(item.id)}
								onChange={(e) => {
									if (selected.includes(item.id)) {
										setSelected(selected.filter((it) => it !== item.id));
									} else {
										setSelected([...selected, item.id]);
									}
									if (e.currentTarget.checked) {
										setShowInputCode(idx);
									}
								}}
								handleSelectImage={() => setImageViewer(item.image)}
							/>
						</div>
					))}
					{bills.length === 0 && (
						<div className="t-selectBill_noti">Không tìm thấy bill</div>
					)}
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						marginTop: 30,
						width: "100%",
						flexShrink: 0,
					}}
				>
					<Button
						loading={loading}
						disabled={loading}
						onClick={getAllBillAction}
					>
						Lấy lại bill
					</Button>
					<div style={{ marginLeft: "16px" }}>
						<Button
							loading={loading}
							disabled={loading}
							onClick={() => {
								if (billNull) {
									setShowcapture(true);
								} else if (selected.length === 0) {
									errorFunc(`Vui lòng chọn bill thích hợp`);
								} else {
									submitSelectBill();
								}
							}}
						>
							{billNull ? "Chụp bill để đi tiếp" : "Tiếp tục"}
						</Button>
					</div>
				</div>
				{showCapture && (
					<div className="t-selectBill_capture">
						<div className="t-selectBill_capture_images">
							{images.map((item) => (
								<div key={item.id} className="t-selectBill_capture_image">
									<BillSelect
										imgSrc={URL.createObjectURL(item.file)}
										checked={selected.includes(item.id)}
										onChange={(e) => {
											if (e.currentTarget.checked) {
												setShowInputCode(item.id);
												setSelected([...selected, item.id]);
											} else {
												setSelected(selected.filter((it) => it !== item.id));
											}
										}}
										handleSelectImage={() =>
											setImageViewer(URL.createObjectURL(item.file))
										}
									/>
								</div>
							))}
						</div>
						<div className="t-selectBill_capture_controls">
							<div className="t-selectBill_capture_flex">
								<div className="t-selectBill_capture_label">
									<Button onClick={() => setShowcapture(false)}>
										Quay lại
									</Button>
								</div>
								<label htmlFor="capture" className="t-selectBill_capture_label">
									<Button>
										{images.length > 0 ? "Chụp tiếp" : "Chụp hình"}
									</Button>
								</label>
							</div>
							<div className="t-selectBill_capture_submit">
								<Button
									loading={loading}
									onClick={() => {
										if (images.length === 0) {
											errorFunc(`Vui lòng chọn bill thích hợp`);
										} else {
											submitBillCapture();
										}
									}}
								>
									Tiếp tục
								</Button>
							</div>
							<input
								id="capture"
								type="file"
								hidden
								accept="image/*"
								onChange={handleCapture}
							/>
						</div>
					</div>
				)}
			</div>
			{imageViewer && (
				<div
					className="t-selectBill_preview"
					style={{ height: `${height - 64}px` }}
				>
					<TransformWrapper>
						<TransformComponent>
							<img src={imageViewer} alt="test" />
						</TransformComponent>
					</TransformWrapper>
					<Button modifiers="mediumSize" onClick={() => setImageViewer("")}>
						Đóng
					</Button>
				</div>
			)}
			{showInputCode > -1 && (
				<div
					className="t-selectBill_inputCode"
					style={{ height: `${height - 64}px` }}
				>
					<TextField
						name="inputCode"
						placeholder="Nhập số hoá đơn"
						type="text"
						onChange={(e) => setInputCode(e.currentTarget.value)}
					/>
					<Button
						loading={loading}
						onClick={() => {
							if (!inputCode) {
								errorFunc(`Vui lòng nhập số hóa đơn`);
							} else if (showCapture) {
								checkExistBillCapture();
							} else {
								checkExistBill();
							}
						}}
					>
						Tiếp tục
					</Button>
					<TransformWrapper>
						<TransformComponent>
							<img
								src={
									showCapture
										? URL.createObjectURL(images[showInputCode].file)
										: bills[showInputCode].image
								}
								alt="test"
							/>
						</TransformComponent>
					</TransformWrapper>
				</div>
			)}
			{/* <Modal
				heading="Nhập thông tin"
				isOpen={isOpen}
				closable
				shouldCloseOnEsc
				modifiers="confirm"
				shouldCloseOnOverlayClick
				onCloseModal={() => setIsOpen(false)}
			>
				<FormContainer validationSchema={{}} register={register}>
					{(selected.length > 0
						? Array(selected.length).fill(0)
						: Array(images.length).fill(0)
					).map((_, idx) => (
						<div
							className="t-selectBill_input u-mt-16"
							key={`receiptCode${idx.toString()}`}
						>
							<TextfieldHookForm
								name={`receiptCode${idx}`}
								placeholder={`Nhập mã đơn hàng thứ ${idx + 1}`}
								type="text"
							/>
						</div>
					))}
				</FormContainer>
				<div className="d-flex align-items-center">
					<div className="ml-auto mr-auto u-ml-sm-10 u-mt-15">
						<Button
							type="submit"
							onClick={() => submitSelectBill(isCapture)}
							loading={loading}
						>
							Tiếp tục
						</Button>
					</div>
				</div>
			</Modal> */}
		</>
	);
};

export default SelectBill;
