export const delay = (ms: number): Promise<void> =>
	new Promise((resolve) => setTimeout(resolve, ms));

// Rule chọn quà 1, 2, 3
export const distributeNumber = (sum: number, arr: number[]) => {
	const res = arr.find((f) => f > 0);
	const resIdx = arr.findIndex((f) => f > 0);
	const remaining = sum - Number(res) * (resIdx + 1);
	const arrResult = [];
	const idxRemaining: number[] = [];
	const remainingArr = arr
		.map((val, idx) => {
			if (val === 0) {
				idxRemaining.push(idx);
				return idx + 1;
			}
			return val;
		})
		.filter((f) => f !== res);

	const devideRemaining = Math.floor(remaining / Math.max(...remainingArr));
	const devideRemainingMod = remaining % Math.max(...remainingArr);

	arrResult[resIdx] = res;
	arrResult[Math.max(...idxRemaining)] =
		remaining < Math.max(...remainingArr) ? 0 : devideRemaining;
	arrResult[Math.min(...idxRemaining)] =
		remaining < Math.min(...remainingArr) ? 0 : devideRemainingMod;

	return arrResult;
};

export const imageLoader = (url: string) =>
	new Promise<HTMLImageElement>((resolve, reject) => {
		const img = new Image();

		img.crossOrigin = "anonymous";

		img.onload = () => {
			resolve(img);
		};
		img.onerror = (err) => {
			reject(err);
		};
		img.src = url;
	});

export const dataURLtoFile = (dataUrl: string, filename: string) => {
	const arr = dataUrl.split(",");
	// const mime = arr[0].match(/:(.*?);/)[1];
	const mine = "image/jpeg";
	const bstr = atob(arr[1]);
	let n = bstr.length;
	const u8arr = new Uint8Array(n);

	// eslint-disable-next-line no-plusplus
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}

	return new File([u8arr], filename, { type: mine });
};

export const formatTime = (date: Date) =>
	`${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:${
		date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
	}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`;
export const formatDateYYYYMMDD = () => {
	const dateFormat = new Date();
	let day: string | number = dateFormat.getDate();
	let month: string | number = dateFormat.getMonth() + 1;
	if (day < 10) {
		day = `0${day}`;
	}
	if (month < 10) {
		month = `0${month}`;
	}
	return `${dateFormat.getFullYear()}${month}${day}`;
};
export const formatDateHHMMSS = () => {
	const dateFormat = new Date();
	let hours: string | number = dateFormat.getHours();
	let minutes: string | number = dateFormat.getMinutes();
	let seconds: string | number = dateFormat.getSeconds();
	if (hours < 10) {
		hours = `0${hours}`;
	}
	if (minutes < 10) {
		minutes = `0${minutes}`;
	}
	if (seconds < 10) {
		seconds = `0${seconds}`;
	}
	return `${hours}${minutes}${seconds}`;
};
export const generateImageFileName = () =>
	`ensure_doiqua_${formatDateYYYYMMDD()}_${formatDateHHMMSS()}.jpeg`;
export const resizeImage = async (
	file: File,
	callBack: (val: File) => void
) => {
	const canvas = document.createElement("canvas");
	const ctx = canvas.getContext("2d");
	if (!ctx) {
		return;
	}
	const image = new Image();
	image.src = URL.createObjectURL(file);
	await new Promise((resolve) => {
		image.onload = resolve;
	});

	const maxSize = 1280;
	let { width, height } = image;
	if (width > height) {
		if (width > maxSize) {
			height *= maxSize / width;
			width = maxSize;
		}
	} else if (height > maxSize) {
		width *= maxSize / height;
		height = maxSize;
	}
	canvas.width = width;
	canvas.height = height;
	ctx.drawImage(image, 0, 0, width, height);
	canvas.toBlob((b) => {
		if (b) {
			const fileResize = new File([b], generateImageFileName(), {
				type: "image/jpeg",
			});
			callBack(fileResize);
		}
	}, "image/jpeg");
};
