import React from "react";

import { mapModifiers } from "helpers/component";

export type IconName =
	| "green-check-circle"
	| "yellow-exclamation-triangle"
	| "blue-info-circle"
	| "aqua-dots"
	| "aqua-large-spinner"
	| "aqua-small-spinner"
	| "red-exclamation-circle"
	| "white-close-circle"
	| "white-spinner"
	| "close-circle-bg-gray"
	| "grey-times";

export interface Props {
	iconName: IconName;
	clickable?: boolean;
	onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const Icon: React.FC<Props> = ({ iconName, clickable, onClick }) => {
	return (
		<div
			className={mapModifiers("a-icon", iconName, clickable && "clickable")}
			aria-hidden="true"
			onClick={clickable ? onClick : undefined}
		/>
	);
};
