/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";

import { Button } from "components/atoms/Button";
import { Heading } from "components/atoms/Heading";
import { Text } from "components/atoms/Text";
import { TextfieldHookForm } from "components/atoms/TextField";
import { toastSingleMode } from "components/atoms/Toastify";
import { FormField } from "components/molecules/FormField";
import { Container } from "components/organisms/Grid";
import { Modal } from "components/organisms/Modal";
import { General } from "components/pages/general";
import { FormContainer, useFormContainer } from "helpers/form";
import { useAsyncAction } from "hooks/useAsyncAction";
import { apiPrizesExchange } from "services/Exchange";
import { ProductOfBillSelect } from "services/PG/types";

export interface Props {
	isVerifyOtp: boolean;
	initProduct?: ProductOfBillSelect;
	channel: "MT" | "GT" | "CV";
	onSubmit?: (hasDraw: boolean) => void;
	goBack: () => void;
}

const PRODUCT_FORM_FIELD = [
	{
		name: "enGoldGH",
		label: "Ensure Gold Green Health",
		isMultiple: true,
	},
	{
		name: "enGoldVani",
		label: "Ensure Gold Vani",
		isMultiple: true,
	},
	{
		name: "enGoldVaniLittle",
		label: "Ensure Gold Vani ít ngọt",
		isMultiple: true,
	},
	{
		name: "enGoldCorn",
		label: "Ensure Gold Lúa mạch",
		isMultiple: true,
	},
	{
		name: "enGoldCf",
		label: "Ensure Gold cà phê",
		isMultiple: true,
	},
	// {
	// 	name: "gluVani",
	// 	label: "Glucerna Vani",
	// 	isMultiple: true,
	// },
	// {
	// 	name: "gluCorn",
	// 	label: "Glucerna lúa mạch",
	// 	isMultiple: true,
	// },
	// {
	// 	name: "locGlu",
	// 	label: "Lốc Glucerna Liquid",
	// 	isMultiple: false,
	// },
	// {
	// 	name: "chaiGlu",
	// 	label: "Chai Glucerna Liquid",
	// 	isMultiple: false,
	// },
	{
		name: "locImmune",
		label: "Lốc Ensure Immune",
		isMultiple: false,
	},
];

const ENSURE_GIFT_FORM_FIELD = [
	{
		name: "lythuytinh",
		label: "Quà 1 ly thuỷ tinh",
		isGift: true,
		isRead: false,
	},
	{
		name: "binhlnl",
		label: "Quà 1 bình lock & lock",
		isGift: true,
		isRead: false,
	},
	{
		name: "nhietke",
		label: "Quà 1 nhiệt kế",
		isGift: true,
		isRead: false,
	},
	{
		name: "canEnsure",
		label: "Quà 1 cân điện tử Ensure",
		isGift: true,
		isRead: false,
	},
	{
		name: "binhgiunhietensure",
		label: "Quà 1 bình giữ nhiệt Ensure",
		isGift: true,
		isRead: false,
	},
	{
		name: "binhlac",
		label: "Quà 1 bình lắc",
		isGift: true,
		isRead: false,
	},
	{
		name: "goicotsong",
		label: "Quà 1 gối nâng cột sóng",
		isGift: true,
		isRead: false,
	},
	{
		name: "nonbaohiem",
		label: "Quà 1 nón bảo hiểm",
		isGift: true,
		isRead: false,
	},
	{
		name: "mayvatcam",
		label: "Quà 1 máy vắt cam",
		isGift: true,
		isRead: false,
	},
	{
		name: "binhdunnuoc",
		label: "Quà 1 bình đun nước",
		isGift: true,
		isRead: false,
	},
	{
		name: "massagehongngoai",
		label: "Quà 1 máy massage hồng ngoại",
		isGift: true,
		isRead: false,
	},
	{
		name: "dohuyetapensure",
		label: "Quà 1 máy Đo Huyết Áp Ensure",
		isGift: true,
		isRead: false,
	},
	// {
	// 	name: "goiconghenhacll",
	// 	label: "Quà 1 gối cổ nghe nhạc Lock & Lock",
	// 	isGift: true,
	// 	isRead: false,
	// },
	// {
	// 	name: "mayxaysinhto",
	// 	label: "Quà 1 máy xay sinh tố",
	// 	isGift: true,
	// 	isRead: false,
	// },
	{
		name: "valiensure",
		label: "Quà 1 vali Ensure",
		isGift: true,
		isRead: false,
	},
];

const IMMUNE_GIFT_FORM_FIELD = [
	{
		name: "imelythuytinh",
		label: "Quà 1 ly thuỷ tinh",
		isGift: true,
		isRead: false,
	},
	{
		name: "imebinhlnl",
		label: "Quà 1 bình lock & lock",
		isGift: true,
		isRead: false,
	},
	{
		name: "imecanEnsure",
		label: "Quà 1 cân điện tử Ensure",
		isGift: true,
		isRead: false,
	},
];

const GiftExchange: React.FC<Props> = ({
	isVerifyOtp,
	channel,
	onSubmit,
	goBack,
	initProduct,
}) => {
	const { register, methodsRef } = useFormContainer();
	const [opened, setOpened] = useState(false);

	const errorFunc = (message: string) => {
		toastSingleMode({
			type: "error",
			description: message,
		});
	};
	const [prizesExchange, prizesExchangeState] = useAsyncAction(
		apiPrizesExchange,
		{
			onSuccess: () => {
				const formValues = methodsRef.current.getValues();
				const totalGiftEnsure =
					Number(formValues.lythuytinh || 0) +
					Number(formValues.imelythuytinh || 0) +
					Number(formValues.binhlnl || 0) +
					Number(formValues.imebinhlnl || 0) +
					Number(formValues.canEnsure || 0) +
					Number(formValues.imecanEnsure) +
					Number(formValues.nhietke || 0) +
					Number(formValues.binhgiunhietensure || 0) +
					Number(formValues.binhlac || 0) +
					Number(formValues.goicotsong || 0) +
					Number(formValues.nonbaohiem || 0) +
					Number(formValues.mayvatcam || 0) +
					Number(formValues.binhdunnuoc || 0) +
					Number(formValues.massagehongngoai || 0) +
					Number(formValues.dohuyetapensure || 0) +
					Number(formValues.goiconghenhacll || 0) +
					Number(formValues.mayxaysinhto || 0) +
					Number(formValues.valiensure || 0);

				if (!onSubmit) return;

				onSubmit(totalGiftEnsure > 0);
			},
			onFailed: (err: any) => {
				const { errors } = err.response.data;
				if (Array.isArray(errors)) {
					errors.forEach((val) => {
						if (val.code === "4223") {
							errorFunc(`Số lượng tồn kho quà không đủ`);
						}
						if (val.code === "4224") {
							errorFunc(
								`Số sản phẩm vượt quá quy định trên ngày. Vui lòng chọn lại`
							);
						}
					});
				}
			},
		}
	);

	/**
	 * SUBMIT
	 */

	const handleSubmit = () => {
		const { getValues } = methodsRef.current;

		const formValues = getValues();
		const totalProductImmune = Number(formValues.locImmune || 0);
		const totalProductInput =
			Number(formValues.enGoldGH || 0) +
			Number(formValues.enGoldCf || 0) +
			Number(formValues.enGoldCorn || 0) +
			Number(formValues.enGoldVani || 0) +
			Number(formValues.enGoldVaniLittle || 0) +
			Number(formValues.enGoldGH850 || 0) +
			Number(formValues.enGoldCf850 || 0) +
			Number(formValues.enGoldCorn850 || 0) +
			Number(formValues.enGoldVani850 || 0) +
			Number(formValues.enGoldVaniLittle850 || 0);
		const totalGift =
			Number(formValues.lythuytinh || 0) +
			Number(formValues.imelythuytinh || 0) +
			Number(formValues.binhlnl || 0) +
			Number(formValues.imebinhlnl || 0) +
			Number(formValues.canEnsure || 0) +
			Number(formValues.imecanEnsure) +
			Number(formValues.nhietke || 0) +
			Number(formValues.binhgiunhietensure || 0) +
			Number(formValues.binhlac || 0) +
			Number(formValues.goicotsong || 0) +
			Number(formValues.nonbaohiem || 0) +
			Number(formValues.mayvatcam || 0) +
			Number(formValues.massagehongngoai || 0) +
			Number(formValues.dohuyetapensure || 0) +
			Number(formValues.binhdunnuoc || 0) +
			Number(formValues.valiensure || 0);

		if (totalProductInput + totalProductImmune === 0) {
			errorFunc("Vui lòng nhập sản phẩm");
			return;
		}

		// if (totalProductInput === 0 && totalProductImmune > 0 && !isVerifyOtp) {
		// 	errorFunc("Vui Lòng Xác Thực OTP");
		// 	return;
		// }
		if (totalGift === 0) {
			errorFunc("Vui lòng nhập đầy đủ thông tin");
			return;
		}
		let totalImmune = Number(formValues.locImmune);
		let totalEnsureGH400 =
			Number(formValues.enGoldGH) +
			Number(formValues.enGoldCf) +
			Number(formValues.enGoldCorn) +
			Number(formValues.enGoldVani) +
			Number(formValues.enGoldVaniLittle);
		let totalEnsure850 =
			Number(formValues.enGoldGH850) +
			Number(formValues.enGoldVani850) +
			Number(formValues.enGoldVaniLittle850) +
			Number(formValues.enGoldCorn850) +
			Number(formValues.enGoldCf850);

		/* Vali */
		const valiensure = Number(formValues.valiensure);
		if (valiensure > 0) {
			if (totalEnsure850 >= 5 * valiensure) {
				totalEnsure850 -= 5 * valiensure;
			} else {
				console.log("vali");
				errorFunc("Sai scheme quà tặng. Vui lòng chọn lại");
				return;
			}
		}
		/* Máy vắt cam */
		const gift3can =
			Number(formValues.mayvatcam) +
			Number(formValues.massagehongngoai) +
			Number(formValues.dohuyetapensure) +
			Number(formValues.binhdunnuoc);
		if (gift3can > 0) {
			if (totalEnsure850 >= 3 * gift3can) {
				totalEnsure850 -= 3 * gift3can;
			} else {
				console.log("qua3lon");
				errorFunc("Sai scheme quà tặng. Vui lòng chọn lại");
				return;
			}
		}

		/* Nón bảo hiểm */
		const nonbaohiem = Number(formValues.nonbaohiem);
		if (nonbaohiem > 0) {
			if (totalEnsure850 >= nonbaohiem * 2) {
				totalEnsure850 -= nonbaohiem * 2;
			} else {
				console.log("nonbaohiem");
				errorFunc("Sai scheme quà tặng. Vui lòng chọn lại");
				return;
			}
		}

		/* Quà đổi GH 850 */
		const quadoiGH850 =
			Number(formValues.nhietke) +
			Number(formValues.canEnsure) +
			Number(formValues.binhgiunhietensure) +
			Number(formValues.binhlac) +
			Number(formValues.goicotsong);
		if (quadoiGH850 > 0) {
			if (totalEnsure850 >= quadoiGH850) {
				totalEnsure850 -= quadoiGH850;
			} else {
				console.log("qua1lon");
				errorFunc("Sai scheme quà tặng. Vui lòng chọn lại");
				return;
			}
		}

		/* Bình Lock&Lock */
		const binhlnl = Number(formValues.binhlnl);
		if (binhlnl > 0) {
			if (totalEnsureGH400 >= binhlnl) {
				totalEnsureGH400 -= binhlnl;
			} else {
				console.log("binhll");
				errorFunc("Sai scheme quà tặng. Vui lòng chọn lại");
				return;
			}
		}
		/* Ly thủy tinh */
		const lythuytinh = Number(formValues.lythuytinh);
		if (lythuytinh > 0) {
			if (totalEnsureGH400 >= lythuytinh) {
				totalEnsureGH400 -= lythuytinh;
			} else if (totalEnsureGH400 + totalEnsure850 >= lythuytinh) {
				totalEnsure850 -= lythuytinh - totalImmune;
				totalEnsureGH400 = 0;
			} else {
				console.log("lythuytinh");
				errorFunc("Sai scheme quà tặng. Vui lòng chọn lại");
				return;
			}
		}

		/* immune cân điện tử */
		const imeCanEnsure = Number(formValues.imecanEnsure);
		if (imeCanEnsure > 0) {
			if (totalImmune >= imeCanEnsure * 3) {
				totalImmune -= imeCanEnsure * 3;
			} else {
				console.log("imeCanEnsure");
				errorFunc("Sai scheme quà tặng. Vui lòng chọn lại");
				return;
			}
		}

		/* immune binh ll */
		const imebinhll = Number(formValues.imebinhlnl);
		if (imebinhll > 0) {
			if (totalImmune >= imebinhll * 2) {
				totalImmune -= imebinhll * 2;
			} else {
				console.log("imebinhll");
				errorFunc("Sai scheme quà tặng. Vui lòng chọn lại");
				return;
			}
		}

		/* immune lythuytinh */
		const imelythuytinh = Number(formValues.imelythuytinh);
		if (imelythuytinh > 0) {
			if (totalImmune >= imelythuytinh) {
				totalImmune -= imelythuytinh;
			} else {
				console.log("imelythuytinh");
				errorFunc("Sai scheme quà tặng. Vui lòng chọn lại");
				return;
			}
		}

		if (totalImmune > 0 || totalEnsureGH400 > 0 || totalEnsure850 > 0) {
			setOpened(true);
		} else {
			prizesExchange({
				EG_400_GH: Number(formValues.enGoldGH) || undefined,
				EG_400_CF: Number(formValues.enGoldCf) || undefined,
				EG_400_LUA_MACH: Number(formValues.enGoldCorn) || undefined,
				EG_400_VANI: Number(formValues.enGoldVani) || undefined,
				EG_400_VANI_IT_NGOT: Number(formValues.enGoldVaniLittle) || undefined,
				EG_850_GH: Number(formValues.enGoldGH850) || undefined,
				EG_850_CF: Number(formValues.enGoldCf850) || undefined,
				EG_850_LUA_MACH: Number(formValues.enGoldCorn850) || undefined,
				EG_850_VANI: Number(formValues.enGoldVani850) || undefined,
				EG_850_VANI_IT_NGOT:
					Number(formValues.enGoldVaniLittle850) || undefined,
				LOC_IMMUNE_E: Number(formValues.locImmune) || undefined,
				LY_THUY_TINH:
					Number(formValues.lythuytinh) + Number(formValues.imelythuytinh) ||
					undefined,
				BINH_LL:
					Number(formValues.binhlnl) + Number(formValues.imebinhlnl) ||
					undefined,
				CAN_DIEN_TU:
					Number(formValues.canEnsure) + Number(formValues.imecanEnsure) ||
					undefined,
				NHIET_KE: Number(formValues.nhietke) || undefined,
				BINH_GIU_NHIET_E: Number(formValues.binhgiunhietensure) || undefined,
				BINH_LAC: Number(formValues.binhlac) || undefined,
				GOI_NANG_COT_SONG: Number(formValues.goicotsong) || undefined,
				NON_BAO_HIEM: Number(formValues.nonbaohiem) || undefined,
				MAY_VAT_CAM: Number(formValues.mayvatcam) || undefined,
				MAY_MASSAGE_HN: Number(formValues.massagehongngoai) || undefined,
				DO_HUYET_AP_E: Number(formValues.dohuyetapensure) || undefined,
				BINH_DUN_NUOC: Number(formValues.binhdunnuoc) || undefined,
				VALI_LL_E: Number(formValues.valiensure) || undefined,
			});
		}
	};

	const handleSubmitLastest = () => {
		const { getValues } = methodsRef.current;

		const formValues = getValues();

		prizesExchange({
			EG_400_GH: Number(formValues.enGoldGH) || undefined,
			EG_400_CF: Number(formValues.enGoldCf) || undefined,
			EG_400_LUA_MACH: Number(formValues.enGoldCorn) || undefined,
			EG_400_VANI: Number(formValues.enGoldVani) || undefined,
			EG_400_VANI_IT_NGOT: Number(formValues.enGoldVaniLittle) || undefined,
			EG_850_GH: Number(formValues.enGoldGH850) || undefined,
			EG_850_CF: Number(formValues.enGoldCf850) || undefined,
			EG_850_LUA_MACH: Number(formValues.enGoldCorn850) || undefined,
			EG_850_VANI: Number(formValues.enGoldVani850) || undefined,
			EG_850_VANI_IT_NGOT: Number(formValues.enGoldVaniLittle850) || undefined,
			LOC_IMMUNE_E: Number(formValues.locImmune) || undefined,
			LY_THUY_TINH:
				Number(formValues.lythuytinh) + Number(formValues.imelythuytinh) ||
				undefined,
			BINH_LL:
				Number(formValues.binhlnl) + Number(formValues.imebinhlnl) || undefined,
			CAN_DIEN_TU:
				Number(formValues.canEnsure) + Number(formValues.imecanEnsure) ||
				undefined,
			NHIET_KE: Number(formValues.nhietke) || undefined,
			BINH_GIU_NHIET_E: Number(formValues.binhgiunhietensure) || undefined,
			BINH_LAC: Number(formValues.binhlac) || undefined,
			GOI_NANG_COT_SONG: Number(formValues.goicotsong) || undefined,
			NON_BAO_HIEM: Number(formValues.nonbaohiem) || undefined,
			MAY_VAT_CAM: Number(formValues.mayvatcam) || undefined,
			MAY_MASSAGE_HN: Number(formValues.massagehongngoai) || undefined,
			DO_HUYET_AP_E: Number(formValues.dohuyetapensure) || undefined,
			BINH_DUN_NUOC: Number(formValues.binhdunnuoc) || undefined,
			VALI_LL_E: Number(formValues.valiensure) || undefined,
		});
	};

	useEffect(() => {
		if (initProduct) {
			methodsRef.current.reset({
				enGoldGH: initProduct.EG_400_GH,
				enGoldGH850: initProduct.EG_850_GH,
				enGoldVani: initProduct.EG_400_VANI,
				enGoldVani850: initProduct.EG_850_VANI,
				enGoldVaniLittle: initProduct.EG_400_VANI_IT_NGOT,
				enGoldVaniLittle850: initProduct.EG_850_VANI_IT_NGOT,
				enGoldCorn: initProduct.EG_400_LUA_MACH,
				enGoldCorn850: initProduct.EG_850_LUA_MACH,
				enGoldCf: initProduct.EG_400_CF,
				enGoldCf850: initProduct.EG_850_CF,
				locImmune: initProduct.LOC_IMMUNE_E,
			});
		}
	}, [initProduct, methodsRef]);

	return (
		<General helmet={<title>Thông tin đổi quà</title>}>
			<Container>
				<div className="t-giftExchange">
					<section className="mt-2 pt-4">
						<div className="u-pb-5">
							<Heading>Sản phẩm</Heading>
						</div>
						<FormContainer validationSchema={{}} register={register}>
							<div className="d-flex t-giftExchange_title">
								<div className="t-giftExchange_title-label" />
								<div className="d-flex flex-grow-1 justify-content-between">
									<div className="w-40">
										<Text>Lon 400g</Text>
									</div>
									<div className="w-40">
										<Text>Lon 850g</Text>
									</div>
								</div>
							</div>
							{PRODUCT_FORM_FIELD.map((val, idx) => (
								<FormField
									key={`item-${idx.toString()}`}
									label={<span>{val.label}</span>}
									modifiers="giftInfoMT"
								>
									<div className="d-flex justify-content-between">
										<div className="w-40">
											<TextfieldHookForm
												name={val.name}
												type="number"
												min={0}
												placeholder="0"
											/>
										</div>
										{val.isMultiple && (
											<div className="w-40">
												<TextfieldHookForm
													name={`${val.name}850`}
													type="number"
													min={0}
													placeholder="0"
												/>
											</div>
										)}
									</div>
								</FormField>
							))}
							<div className="u-pb-5 pt-4">
								<Heading>Quà tặng Ensure</Heading>
							</div>
							{ENSURE_GIFT_FORM_FIELD.map((val, idx) => (
								<FormField
									key={`gift-${idx.toString()}`}
									label={val.label}
									modifiers="giftInfoMT"
								>
									<div className="w-60">
										<TextfieldHookForm
											name={val.name}
											type="number"
											min={0}
											placeholder="0"
											readOnly={val.isRead}
										/>
									</div>
								</FormField>
							))}
							<div className="u-pb-5 pt-4">
								<Heading>Quà tặng Immune</Heading>
							</div>
							{IMMUNE_GIFT_FORM_FIELD.map((val, idx) => (
								<FormField
									key={`gift-${idx.toString()}`}
									label={val.label}
									modifiers="giftInfoMT"
								>
									<div className="w-60">
										<TextfieldHookForm
											name={val.name}
											type="number"
											min={0}
											placeholder="0"
											readOnly={val.isRead}
										/>
									</div>
								</FormField>
							))}
							<div className="d-flex align-items-center">
								<div className="ml-auto mr-auto u-ml-sm-10 u-mt-15">
									<Button
										modifiers="mediumSize"
										onClick={goBack}
										loading={prizesExchangeState.loading}
									>
										Quay lại
									</Button>
								</div>
								<div className="ml-auto mr-auto u-ml-sm-10 u-mt-15">
									<Button
										modifiers="mediumSize"
										onClick={handleSubmit}
										loading={prizesExchangeState.loading}
									>
										TIẾP TỤC
									</Button>
								</div>
							</div>
						</FormContainer>
					</section>
				</div>
			</Container>
			<Modal
				heading="Thông báo"
				isOpen={opened}
				closable
				shouldCloseOnEsc
				modifiers="confirm"
				shouldCloseOnOverlayClick
				onCloseModal={() => setOpened(false)}
			>
				<Text centered modifiers={["yankeesBlue", "18x26"]}>
					Bạn vẫn có thể đổi thêm quà. <br />
					Bạn có chắc chắn muốn đi tiếp không?
				</Text>
				<div className="d-flex align-items-center">
					<div className="ml-auto mr-auto u-ml-sm-10 u-mt-15">
						<Button
							onClick={() => setOpened(false)}
							loading={prizesExchangeState.loading}
						>
							Thêm quà
						</Button>
					</div>
					<div className="ml-auto mr-auto u-ml-sm-10 u-mt-15">
						<Button
							modifiers="mediumSize"
							onClick={handleSubmitLastest}
							loading={prizesExchangeState.loading}
						>
							TIẾP TỤC
						</Button>
					</div>
				</div>
			</Modal>
		</General>
	);
};

export default GiftExchange;
