import http from "services/http";

import {
	BillForSelect,
	LoginPayload,
	LoginResponse,
	ProductOfBillSelect,
	UserProfile,
} from "./types";

export const apiLogin = ({ shopId, password, phone }: LoginPayload) =>
	http.post<BaseAPIResponse<LoginResponse>>(`/pg/login`, {
		shop_id: shopId,
		password,
		phone,
	});

export const apiGetUserProfile = () =>
	http.get<BaseAPIResponse<UserProfile>>(`/pg/profile`, {
		requireAuthentication: true,
	});

export const apiLogout = () =>
	http.post("/pg/logout", undefined, { requireAuthentication: true });

// eslint-disable-next-line camelcase
export const apiScanQR = (code?: string, short_code?: string) =>
	http.post(
		"/pg/scanner",
		{ scanner_code: code, short_code },
		{ requireAuthentication: true }
	);

export const getAllBillService = async (): Promise<BillForSelect[]> => {
	const response = await http.get<BaseAPIResponse<BillForSelect[]>>(
		"/pg/receipts",
		{ requireAuthentication: true }
	);
	return response.data.data;
};

export const submitSelectBillService = async (
	ids?: number[],
	images?: File[],
	receiptCodes?: string[]
): Promise<ProductOfBillSelect> => {
	const formData = new FormData();
	if (ids) {
		ids.forEach((item, index) => {
			formData.append(`receipt_ids[${index.toString()}]`, String(item));
		});
	}
	if (images) {
		images.forEach((item, index) => {
			formData.append(`images[${index.toString()}]`, item);
		});
	}
	if (receiptCodes) {
		receiptCodes.forEach((item, index) => {
			formData.append(`receiptCodes[${index.toString()}]`, item);
		});
	}
	const response = await http.post("/pg/receipts", formData, {
		requireAuthentication: true,
	});
	return response.data.data.reduce(
		(prev: ProductOfBillSelect, curr: { code: string; quantity: number }) => ({
			...prev,
			[curr.code]: curr.quantity,
		}),
		{}
	);
};
