import { ChangeEvent, useEffect } from "react";

import * as Yup from "yup";

import banner from "assets/images/common/banner.png";
import { Button } from "components/atoms/Button";
import { Image } from "components/atoms/Image";
import { PhonefieldHookForm } from "components/atoms/PhoneField";
import { TextField, TextfieldHookForm } from "components/atoms/TextField";
import { toastSingleMode } from "components/atoms/Toastify";
import { FormField } from "components/molecules/FormField";
import { Container } from "components/organisms/Grid";
import { General } from "components/pages/general";
import { BasePageProps } from "helpers/component";
import debounce from "helpers/debounce";
import { FormContainer } from "helpers/form";
import { useAsyncAction } from "hooks/useAsyncAction";
import { useUserAuthAction } from "hooks/useUserAuthAction";
import { LoginPayload } from "services/PG/types";
import { apiGetShop } from "services/Shop";
import { useAppDispatch } from "store";
import storesSlice from "store/Stores/reducer";

type FormData = Omit<LoginPayload, "shopId"> & { codeShop: string };
type LoginError = {
	response: { data: { errors: { code: string; title: string }[] } };
};

const phoneRegExp = /^0[0-9]{9}$/g;

const loginValidationSchema = Yup.object({
	codeShop: Yup.string().required("Vui lòng nhập mã cửa hàng!"),
	password: Yup.string().required("Vui lòng nhập mật khẩu!"),
	phone: Yup.string()
		.required("Vui lòng nhập số điện thoại!")
		.matches(phoneRegExp, "Vui lòng nhập số điện thoại hợp lệ!"),
});

const IndexPage: React.FC<BasePageProps> = () => {
	const dispatch = useAppDispatch();

	const [getShopExec, shopState] = useAsyncAction(apiGetShop, {
		onFailed: () => {
			toastSingleMode({
				type: "error",
				description: "Mã shop đã xảy ra lỗi!",
			});
		},
	});

	const { handleLogin } = useUserAuthAction();

	const [handleLoginExec, loginState] = useAsyncAction(handleLogin, {
		onSuccess: () => {
			const { channel, id, name, code } = shopState?.data?.data?.data || {};
			if (!channel || typeof id !== "number" || !name || !code)
				throw new Error("Thông tin cửa hàng không tồn tại!");
			dispatch(
				storesSlice.actions.updateStoreInfors({
					channel: channel as "MT" | "GT",
					code,
					id,
					name,
				})
			);
			dispatch(storesSlice.actions.updateProductType("glucerna"));
		},
		onFailed: (error: LoginError) => {
			toastSingleMode({
				type: "error",
				description:
					error?.response?.data?.errors?.[0]?.code === "4221"
						? "Mã cửa hàng đã đăng nhập trên thiết bị khác!"
						: "Thông tin đăng nhập không chính xác!",
			});
		},
	});

	const handleChangeCodeShopDebounce = debounce(
		(event) =>
			getShopExec((event as ChangeEvent<HTMLInputElement>).target.value),
		1000
	);

	const onSubmitLogin = ({ password, phone }: FormData) => {
		const shopId = shop?.id;

		if (typeof shopId !== "number") {
			toastSingleMode({
				type: "error",
				description: "Mã shop đã xảy ra lỗi!",
			});
			return;
		}

		handleLoginExec({ phone, password, shopId });
	};

	const shop = shopState.data?.data?.data;

	useEffect(() => {
		dispatch(storesSlice.actions.updateProductType("default"));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<General helmet={<title>Đăng nhập</title>}>
			<div style={{ maxWidth: 540 }} className="mx-auto">
				<Image
					src={banner}
					alt="banner"
					aspectRatio="loginbanner"
					modifiers="transparent"
				/>
			</div>
			<Container className="u-mt-20 u-pt-5">
				<FormContainer
					validationSchema={loginValidationSchema}
					onSubmit={onSubmitLogin}
				>
					<FormField label="Mã cửa hàng">
						<TextfieldHookForm
							name="codeShop"
							onChange={handleChangeCodeShopDebounce}
						/>
					</FormField>

					<FormField label="Kênh thực hiện">
						<TextField readOnly value={shop?.channel || ""} />
					</FormField>

					<FormField label="Tên cửa hàng">
						<TextField readOnly value={shop?.name} />
					</FormField>

					<FormField label="Số điện thoại">
						<PhonefieldHookForm name="phone" />
					</FormField>

					<FormField label="Mật khẩu">
						<TextfieldHookForm name="password" type="password" />
					</FormField>

					<div className="d-flex justify-content-center u-mt-20 u-pt-15">
						<Button
							type="submit"
							loading={loginState.loading}
							disabled={loginState.loading}
						>
							Đăng nhập
						</Button>
					</div>
				</FormContainer>
			</Container>
		</General>
	);
};

export default IndexPage;
